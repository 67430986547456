import React from "react"
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'

import styles from '../styles/contact.module.css'

export default function Contact({ data }) {
  return (
    <Layout title="Contact">
      <h1>{data.markdownRemark.frontmatter.header}</h1>
      <div className="center-text">
        {data.markdownRemark.frontmatter.description ? <ReactMarkdown source={data.markdownRemark.frontmatter.description} /> : null}
      </div>
      <div className={styles.contact_form}>
        <form name="contact" method="POST" data-netlify="true" data-netlify-honeypot="bot-field" action="/success">
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="name">Name</label>
          <input type="text" id="name" name="name" required />
          <label htmlFor="email">Email</label>
          <input type="email" id="email" name="email" required />
          <label htmlFor="phone">Phone</label>
          <input type="tel" id="phone" name="phone" required />
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" required></textarea>
          <div className="flex-row" style={{ paddingTop: '1rem' }}>
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query ContactQuery {
  markdownRemark(frontmatter: {title: {eq: "Contact"}}) {
    frontmatter {
      header
      description
    }
  }
}
`